import { Component, Vue, Mixins } from 'vue-property-decorator';
import template from './List.Template.vue';
import TemplateOperationListValidator from './TemplateOperationListValidator';
import { ApiService } from '@/services/base/ApiService';
import { AxiosResponse } from 'axios';
import { Result, PagingAndSorting } from '@/shared/models';
import { ArreteReferentiel, TemplateOperation } from '@/models';
import { DateHelper, BooleanHelper } from '../../../../shared/helpers';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import { Getter, Action, Mutation } from 'vuex-class';
import { ReferentielStoreMethods, getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { ValeurReferentielle, TypeValeurReferentielle } from '@/shared/models';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { UserProfile } from '@/store/modules/auth/types';
import { ApiHelper } from '@/services/ApiHelper';
import { FileContentResult } from '@/shared/models';
import { TemplateOperationCriteria } from './TemplateOperationCriteria.model';
import { TemplateOperationStoreMethods } from '../../../../store/modules/TemplateOperation/TemplateOperationStore';

@Component({
    ...template,
    name: 'TemplateOperationsList',
})
export default class TemplateOperationsList extends Mixins(TemplateOperationListValidator, GrilleMixin) {

    // Définition de refs.
    public $refs!: Vue['$refs'] & {
        form: HTMLFormElement
    };

    /**
     * Critères de recherche.
     */
    public templateOperationCriteria: TemplateOperationCriteria = new TemplateOperationCriteria();

    @Getter(TemplateOperationStoreMethods.GET_LAST_FILTERS_TEMPLATE_OPERATION)
    public lastFilters: TemplateOperationCriteria;

    @Mutation(TemplateOperationStoreMethods.ADD_LAST_FILTERS_TEMPLATE_OPERATION)
    public addLastFilters: (lastCriterias: TemplateOperationCriteria) => void;

    public loading: boolean = false;
    public dupliquerDialogBox: boolean = false;
    public duplicationTemplateOperationEnCours: boolean = false;
    public templateOperationSelectionne: TemplateOperation | null = new TemplateOperation();

    @Getter(getterKeyReferentiel(TypeValeurReferentielle.Arrete))
    public arretes: ArreteReferentiel[];
    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES)
    public getValeursReferentielles: (type: TypeValeurReferentielle) => Promise<ValeurReferentielle[]>;

    // Permet se récupérer le profil de l'utilisateur connecté.
    @Getter(AuthStoreMethods.USER_PROFILE)
    private getUserProfile: Promise<UserProfile>;

    // Permet de savoir si c'est un administrateur informatique.
    public estAdministrateurInformatique: boolean = false;

    /**
     *  L'identifiant de la version d'arrêté du template d'opération.
     */
    public arreteId: number | null = null;

    public headers: any[] = [
        { text: 'Id', value: 'Id' },
        { text: 'Version de l\'arrêté', value: 'ArreteLibelle', sortable: false },
        { text: 'Code de l\'opération', value: 'Code' },
        { text: 'Libellé', value: 'Libelle' },
        { text: 'Actif', value: 'Active' },
        { text: 'DET Debut', value: 'DateEngagementDebut' },
        { text: 'DET fin', value: 'DateEngagementFin' },
        { text: 'DAT fin', value: 'DateAchevementFin' },
        { text: '', value: 'Id', sortable: false },
    ];

    public templatesOperations: TemplateOperation[] = new Array<TemplateOperation>();

    /**
     * Le nombre total de templates d'opérations.
     */
    public totalTemplatesOperations: number = 0;

    /**
     * Méthode appelée une fois l'instance de la vue créée.
     */
    public created(): void { 
        this.loading = true;
        this.templateOperationCriteria = this.lastFilters;
        this.getData();
    }

    /**
     * Méthode appelée une fois la vue chargée.
     */
    public mounted(): void {
        this.getUserProfile.then((profile: UserProfile) => {
            if (profile) {
                this.estAdministrateurInformatique = profile.isSuperAdmin;
                this.getValeursReferentielles(TypeValeurReferentielle.Arrete);
            }
        });

        // Tri sur le code de l'opération par défaut.
        this.pagination.sortBy = ["Code"];
    }

    /**
     * Méthode appelée lors d'un changement dans la vue.
     */
    public onChange(): void {
        this.templatesOperations = new Array<TemplateOperation>();
        this.getData();
    }

    /*
     * Récupération des données.
     */
    public getData() {
        this.addLastFilters(this.templateOperationCriteria);
        this.templateOperationCriteria.pagingAndSorting = PagingAndSorting.buildFromPaginationObject(this.pagination);
        if (this.templateOperationCriteria.inclureInactif) {
            this.templateOperationCriteria.pagingAndSorting.sortExpression = 'Active asc,Code asc'
        }
        this.loading = true;
        const fetchAllTemplatesOperationsApi = new ApiService<TemplateOperation>('/templateOperations/list');
        return new Promise<AxiosResponse<Result<TemplateOperation[]>>>((resolve, reject) => {
            return fetchAllTemplatesOperationsApi.getWhere(this.templateOperationCriteria.queryString).then((response) => {
                this.templatesOperations = response.data.data;
                this.totalTemplatesOperations = response.data.totalCount;
                this.$set(this.pagination, 'totalItems', this.totalTemplatesOperations);
                resolve(response);
            })
                .catch((error: { response: any; }) => {
                    reject(error.response);
                })
                .finally(() => this.loading = false);
        });
    }

    /**
     * Ouverture de la popup de duplication de template.
     * @param item template d'opération.
     */
    public ouvrirPopupDupliquerItem(item: TemplateOperation): void {
        if (item && item.id && parseInt(item.id.toString(), 10) > 0) {
            this.dupliquerDialogBox = true;
            this.duplicationTemplateOperationEnCours = false;
            this.templateOperationSelectionne = item;
        }
    }

    /**
     * Fermeture de la popup de duplication de template.
     */
    public fermerPopupDupliquerItem(): void {
        this.dupliquerDialogBox = false;
        this.duplicationTemplateOperationEnCours = false;
        this.templateOperationSelectionne = null;
    }

    /**
     * Duplication d'un template d'opération.
     */
    public dupliquerItem(): void {
        const item = this.templateOperationSelectionne;
        if (item != null && typeof item !== 'undefined' && item.id && parseInt(item.id.toString(), 10) > 0 && (+this.arreteId) >= 0) {
            this.duplicationTemplateOperationEnCours = true;
            this.$http.post(`/templateOperations/${item.id}/dupliquer`, {
                templateOperationId: item.id,
                templateOperationSourceId: item.id,
                arreteId: this.arreteId,
                code: `${item.code}_Dupliqué`,
            }).then((result) => {
                if (!result.data.isError && result.data.data && result.data.data.templateOperationDuplique) {
                    this.templatesOperations.push(result.data.data.templateOperationDuplique);
                }
            }).finally(() => this.fermerPopupDupliquerItem());
        }
    }

    /**
     * Suppression d'un template d'opération.
     * @param item template d'opération.
     */
    public deleteItem(item: TemplateOperation): void {
        if (item && item.id && parseInt(item.id.toString(), 10) > 0) {
            if (confirm('Etes vous sûr de vouloir supprimer cet élement ?')) {
                const index = this.templatesOperations.indexOf(item);
                this.$http.delete(`/templateOperations/${item.id}`).then((result) => {
                    if (!result.data.isError) {
                        this.templatesOperations.splice(index, 1);
                    }
                }).finally(() => this.loading = false);
            }
        }
    }

    /**
     * Récupération du référentiel.
     * @param url url.
     */
    public getReferentiel<T>(url: string) {
        const referentielService = new ApiService<T>(url);
        return new Promise<AxiosResponse<Result<T[]>>>((resolve, reject) => {
            return referentielService.getAll().then((response: any) => {
                resolve(response.data.data);
            }).catch((error: { response: any; }) => {
                reject(error.response);
            });
        });
    }

    /**
     * Formatage de date.
     * @param value la date.
     */
    public formatDate(value: string): string {
        return DateHelper.format(value);
    }

    /**
     * Convertie un booléen en string Oui/Non.
     * @param value Le booléen.
     */
    public formatBooleen(value: boolean): string {
        return BooleanHelper.toStringOuiNon(value);
    }
    /**
     * Télécharger du template attestation sur l'honneur.
     * @param item template d'opération.
     */
    public telechargerAttestationHonneur(item: TemplateOperation): void {
        if (item && item.id > 0 && item.cheminRelatifTemplateAttestationHonneur) {
            const path = btoa(unescape(encodeURIComponent(item.cheminRelatifTemplateAttestationHonneur.trim())));

            const apiExport = new ApiService<FileContentResult>(`/attestationHonneur/telechargerTemplateAttestationHonneur/${path}`);
            this.loading = true;
            apiExport.getWhereSingle(null).then((reponse) => {
                ApiHelper.createAndDownloadBlobFile(reponse.data);
            }).finally(() => (this.loading = false));
        }
    }
    /**
     * Télécharger du template attestation sur l'honneur.
     * @param item template d'opération.
     */
    public telechargerScriptExportTemplate(item: TemplateOperation): void {
        if (item && item.id > 0) {
            const apiExport = new ApiService<FileContentResult>(`/templateOperations/telechargerScriptExportTemplate/${item.id}`);
            this.loading = true;
            apiExport.getWhereSingle(null).then((reponse) => {
                ApiHelper.createAndDownloadBlobFile(reponse.data);
            }).finally(() => (this.loading = false));
        }
    }
}

